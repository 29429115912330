import Vue from "vue";
import VueRouter from "vue-router";
import { AuthManager } from "../utils/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/",
    name: "Home",
    redirect: "/home",
    component: () => import("../views/home.vue"),
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import("../views/home.vue"),
      },
    ],
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/search.vue"),
  },
  {
    path: "/detail",
    name: "Detail",
    component: () => import("../views/detail.vue"),
  },
  {
    path: "/mode-selection",
    name: "ModeSelection",
    component: () => import("../views/mode-selection.vue"),
  },
];

const router = new VueRouter({
  routes,
});

// 挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作 next('/login')强制跳转login
router.beforeEach(async (to, from, next) => {
  // 处理传入的auth参数
  const urlParams = new URLSearchParams(window.location.search);
  const authParam = urlParams.get("auth");

  if (authParam) {
    // 如果有auth参数，先处理认证
    AuthManager.handleIncomingAuth(authParam);
    // 清除URL中的auth参数
    urlParams.delete("auth");
    const newUrl =
      window.location.pathname +
      (urlParams.toString() ? `?${urlParams.toString()}` : "");
    window.history.replaceState({}, "", newUrl);
    // 直接放行到目标路由
    next();
    return;
  }

  // 检查登录状态
  const user = AuthManager.checkLoginStatus();

  // 登录页面直接放行
  if (to.path === "/login") {
    next();
    return;
  }

  // 未登录跳转登录页
  if (!user?.token) {
    next("/login");
    return;
  }

  // 获取当前域名和操作模式
  const currentDomain = window.location.hostname;
  const isNewEnergyDomain = currentDomain === "foton.zyyup.cn";
  const operationMode = AuthManager.checkOperationMode();

  // 只在非auth参数情况下处理域名跳转
  if (operationMode === "new-energy" && !isNewEnergyDomain) {
    const redirectUrl = AuthManager.handleNewEnergyRedirect(user);
    window.location.href = redirectUrl;
    return;
  } else if (operationMode === "traditional" && isNewEnergyDomain) {
    const redirectUrl = AuthManager.handleTraditionalRedirect(user);
    window.location.href = redirectUrl;
    return;
  }

  // 处理模式选择页面逻辑
  if (!operationMode && to.path !== "/mode-selection") {
    next("/mode-selection");
    return;
  }

  if (operationMode && to.path === "/mode-selection") {
    next("/");
    return;
  }

  next();
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;

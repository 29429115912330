<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {
    var lang = window.localStorage.getItem("lang")
      ? window.localStorage.getItem("lang")
      : "zh";
    document.title = lang == "en" ? "foton" : "福田汽车";
  },
  methods: {},
};
</script>

<style lang="scss">
@import "./assets/css/base.css";
html,
body {
  min-width: 1200px;
  margin: 0 auto;
}
* {
  font-family: FotonType-Medium;
}
.b {
  font-weight: bold;
  font-family: FotonType-Bold;
}
b,
strong {
  font-family: FotonType-Bold;
}
</style>

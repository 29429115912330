export function getToken() {
  return window.localStorage.getItem("access_token");
}

export function setToken(token) {
  return window.localStorage.setItem("access_token", token);
}

export function removeToken() {
  return window.localStorage.removeItem("access_token");
}

// 添加加密辅助函数
export const encryptHelper = {
  // 简单的加密密钥
  secretKey: "zyyup_2024",

  // 简单的加密函数
  encrypt(input) {
    try {
      // 将文本与时间戳和密钥组合
      const data = `${input.id}_${input.timestamp}_` + this.secretKey;
      // 使用 Base64 编码
      return btoa(encodeURIComponent(data));
    } catch (e) {
      console.error("加密失败:", e);
      return "";
    }
  },

  // 验证签名
  verifySign(authInfo) {
    try {
      const expectedSign = this.encrypt(authInfo);
      return authInfo.sign === expectedSign;
    } catch (e) {
      console.error("签名验证失败:", e);
      return false;
    }
  },
};

export class AuthManager {
  // 检测是否为移动设备
  static isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  // 检查用户登录状态和token有效性
  static checkLoginStatus() {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}");

    // 检查remember登录
    if (localStorage.getItem("remember") === "true") {
      user = JSON.parse(localStorage.getItem("user") ?? "{}");
    }

    if (this.isTokenExpired(user)) {
      this.clearAuth();
      return null;
    }

    return user;
  }

  // 检查token是否过期
  static isTokenExpired(user) {
    return user.expiryTime && new Date().getTime() > user.expiryTime;
  }

  // 清除认证信息
  static clearAuth() {
    ["remember", "user"].forEach((key) => localStorage.removeItem(key));
    ["user", "operationMode"].forEach((key) => sessionStorage.removeItem(key));
  }

  // 通用的重定向处理方法
  static handleRedirect(user, targetDomain, mode) {
    const timestamp = new Date().getTime();
    const authInfo = {
      id: user.id,
      username: user.username,
      token: true,
      lang: localStorage.getItem("lang") ?? "zh",
      remember: localStorage.getItem("remember") ?? "false",
      timestamp,
      sign: encryptHelper.encrypt({ id: user.id, timestamp }),
      mode: mode,
    };

    const authParams = btoa(JSON.stringify(authInfo));
    this.clearAuth();

    // 根据设备类型决定路由路径
    const routePath = this.isMobileDevice() ? "h5.html" : "index.html";
    return `https://${targetDomain}/${routePath}?auth=${authParams}`;
  }

  // 处理新能源模式跳转
  static handleNewEnergyRedirect(user) {
    return this.handleRedirect(user, "foton.zyyup.cn", "new-energy");
  }

  // 处理传统模式跳转
  static handleTraditionalRedirect(user) {
    return this.handleRedirect(user, "ny.zyyup.cn", "traditional");
  }

  // 检查操作模式
  static checkOperationMode() {
    return sessionStorage.getItem("operationMode");
  }

  // 验证传入的auth参数
  static handleIncomingAuth(authParam) {
    try {
      // Base64解码
      const authInfo = JSON.parse(atob(authParam));

      // 基础字段验证
      if (!this.validateAuthInfo(authInfo)) {
        console.error("认证信息缺少必要字段");
        return;
      }

      // 验证时间戳是否在有效期内（5分钟）
      const validityPeriod = 5 * 60 * 1000;
      if (new Date().getTime() - authInfo.timestamp > validityPeriod) {
        console.error("认证信息已过期");
        return;
      }

      // 验证签名
      if (!encryptHelper.verifySign(authInfo)) {
        console.error("签名验证失败");
        return;
      }

      // 处理记住登录
      const userData = {
        id: authInfo.id,
        username: authInfo.username,
        token: true,
        sign: authInfo.sign,
        expiryTime: new Date().getTime() + 2 * 60 * 60 * 1000,
      };
      if (authInfo.remember === "true") {
        localStorage.setItem("remember", "true");
        localStorage.setItem("user", JSON.stringify(userData));
      } else {
        sessionStorage.setItem("user", JSON.stringify(userData));
      }

      if (authInfo.lang) localStorage.setItem("lang", authInfo.lang);

      if (authInfo.mode) sessionStorage.setItem("operationMode", authInfo.mode);
    } catch (e) {
      console.error("认证处理失败:", e);
      return;
    }
  }

  // 验证auth信息
  static validateAuthInfo(authInfo) {
    return authInfo && authInfo.id && authInfo.timestamp && authInfo.sign;
  }
}

export const lang = {
  lang: "English",
  lang1: "Chinese",
  search: "Search",
  tips_none: "The search content cannot be empty",
  more: "More",
  linkName: "Foton Motor Family Website",
  result: "serach Result",
  webMap: "Website Map",
  contactUs: "Contact Us",
  links: "Friendship Link",

  enterpriseName: "FOTON",
  loginTitle: "Sign in to your account",
  userName: "Account",
  password: "Password",
  loginBtn: "Sign in",
  remember: "Remember me",
  slogan: "Make everything move more freely",
  loginErroMessage1: "Please enter your username",
  loginErroMessage2: "Please enter your password",
  loginErroMessage3: "Password length is between 5 and 20 characters",

  newEnergyMode: "EV Manual",
  traditionalMode: "ICE Manual",
};

export const lang = {
  lang: "英文",
  lang1: "中文",
  search: "搜索",
  tips_none: "搜索内容不可为空",
  more: "点击了解详情",
  linkName: "福田汽车家族网站",
  result: "为您找到以下结果",
  webMap: "网站地图",
  contactUs: "联系我们",
  links: "友情链接",

  enterpriseName: "福田汽车",
  loginTitle: "欢迎回来，请登录您的账号。",
  userName: "用户名",
  password: "密码",
  loginBtn: "登录",
  remember: "记住我",
  slogan: "让万物移动更自由",
  loginErroMessage1: "请输入用户名",
  loginErroMessage2: "请输入密码",
  loginErroMessage3: "密码长度在 5 到 20 个字符",

  newEnergyMode: "新能源手册",
  traditionalMode: "传统能源手册",
};
